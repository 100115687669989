<template>
  <div class="about">
    <Header
      title="Om NetsGo"
      description="Begynn en samtale med oss og finn ditt behov, vi hjelper deg på veien til å transformere din bedrift."
    />
    <AboutSection />
    <Team />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import AboutSection from '@/components/AboutSection.vue'
import Team from '@/components/Team.vue'

export default {
  name: 'About',
  components: {
    AboutSection,
    Header,
    Team
  }
}
</script>
