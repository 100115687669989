<template>
  <div class="contact">
    <Header
      title="Kontakt"
      description="Send oss en mail på kontakt@netsgo.no eller fyll ut kontaktskjemaet med litt informasjon om din bedrift og hvilken løsning dere kan tenke dere. Så tar vi videre kontakt og starter din bedrifts reise på nett!"
    />
    <ContactSection />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import ContactSection from '@/components/ContactSection.vue'

export default {
  name: 'Contact',
  components: {
    Header,
    ContactSection

  }
}
</script>
