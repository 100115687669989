<template>

<div class="navbar">
  <div class="relative bg-gray-50">
    <div class="relative bg-white shadow">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <router-link to="/">
                <span class="sr-only">NetsGo</span>
                <img class="h-6 w-auto sm:h-7" height="100" width="200" img draggable="false" src="@/assets/logo_color.svg" alt="NetsGo logo">
            </router-link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <button @click="isOpenMobile = !isOpenMobile" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">Open menu</span>
              <!-- Heroicon name: menu -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav class="hidden md:flex space-x-10">

              <a href="#" class="text-base font-bold text-gray-500 hover:text-gray-900">
              <router-link to="/">HJEM</router-link>
              </a>

              <a href="#" class="text-base font-bold text-gray-500 hover:text-gray-900">
              <router-link to="/om-oss">OM OSS</router-link>
              </a>

            <a href="#" class="text-base font-bold text-gray-500 hover:text-gray-900">
              <router-link to="/tjenester">TJENESTER</router-link>
            </a>

          </nav>


          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">

            <router-link to="/kontakt">
            <a href="#" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-bold text-gray-900 bg-yellow-400 hover:bg-yellow-500">
              KONTAKT OSS
            </a>
          </router-link>

          </div>

        </div>
      </div>

      <transition
        enter-active-class="duration-200 ease-out"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >

      <div v-show="isOpenMobile" class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-6 w-auto" height="100" width="200" src="@/assets/logo_color.svg" alt="Workflow">
              </div>
              <div class="-mr-2">
                <button @click="isOpenMobile = !isOpenMobile" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">

                <router-link to="/">
                <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                  <span class="ml-3 text-base font-medium text-gray-900">
                    Hjem
                  </span>
                </a>
                </router-link>

                <router-link to="/om-oss">
                <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                  <span class="ml-3 text-base font-medium text-gray-900">
                    Om oss
                  </span>
                </a>
                </router-link>

                <router-link to="/tjenester">
                <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                  <span class="ml-3 text-base font-medium text-gray-900">
                    Tjenester
                  </span>
                </a>
                </router-link>

                <router-link to="/kontakt">
                <a href="#" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                  <span class="ml-3 text-base font-medium text-gray-900">
                    Kontakt oss
                  </span>
                </a>
                </router-link>

              </nav>
            </div>
          </div>
        </div>
      </div>
    </transition>

    </div>
  </div>
</div>



</template>

<script>

export default {
  data: () => ({
    isOpenMobile: false,
  })

}

</script>

<style scoped>

</style>
