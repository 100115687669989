<template>
  <div class="contact">

    <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div class="relative max-w-xl mx-auto">
        <svg class="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg class="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div class="text-center">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Kontakt oss
          </h2>
        </div>
        <div class="mt-12">
          <form class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 form-group" id="contact_form" @submit.prevent="sendEmail">

            <div class="form-group" >
              <label for="first_name" class="block text-sm font-medium text-gray-700 form__label">Fornavn</label>
              <div class="mt-1">
                <input required type="text" name="user_name" autocomplete="given-name" id="first_name" class="form__input py-3 px-4 block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div>
              <label for="last_name" class="block text-sm font-medium text-gray-700">Etternavn</label>
              <div class="mt-1">
                <input required type="text" name="user_lastname" autocomplete="family-name" id="last_name" class="py-3 px-4 block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="company" class="block text-sm font-medium text-gray-700">Bedrift</label>
              <div class="mt-1">
                <input required type="text" name="user_company" autocomplete="organization" id="company" class="py-3 px-4 block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="email" class="block text-sm font-medium text-gray-700">Epost</label>
              <div class="mt-1">
                <input required name="user_email" type="email" autocomplete="email" id="email" class="py-3 px-4 block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="phone_number" class="block text-sm font-medium text-gray-700">Mobil nummer</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input required type="number" name="user_mobile" autocomplete="tel" id="phone_number" class="py-3 px-4 block w-full focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md" placeholder="">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="message" class="block text-sm font-medium text-gray-700">Melding</label>
              <div class="mt-1">
                <textarea required name="message" rows="4" id="message" class="py-3 px-4 block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md"></textarea>
              </div>
            </div>

            <div class="sm:col-span-2">
              <button type="submit" value="Send" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-bold text-gray-900 bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400">
                SEND MELDING
              </button>
              <br/>
              <br/>
              <vue-recaptcha sitekey="6Le24U8aAAAAAKDtDH_bNdn7E2iSMD8iSenK4fif" :loadRecaptchaScript="true"></vue-recaptcha>
            </div>
          </form>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import VueRecaptcha from 'vue-recaptcha';
import Vue from 'vue'
import VueFbCustomerChat from 'vue-fb-customer-chat'

if (typeof window !== 'undefined') {
  window.recaptchaLoaded = new Promise((resolve) => {
    window.vueRecaptchaInit = resolve
  })
  const recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaInit&render=explicit')
  recaptchaScript.setAttribute('async', '')
  recaptchaScript.setAttribute('defer', '')
  document.body.appendChild(recaptchaScript)
}

Vue.use(VueFbCustomerChat, {
page_id: 355297285503692, //  change 'null' to your Facebook Page ID,
theme_color: '#fb923c', // theme color in HEX
locale: 'nb_NO', // default 'en_US'
})

export default {

  methods: {
    sendEmail: (e) => {
      emailjs.sendForm('NetsGoEmail', 'contact_form', e.target, 'user_z6UvsR767GYDtR9bcJKBK')
        .then((result) => {
            alert('Mail sendt!', result.status, result.text);
        }, (error) => {
          alert('Vennligst verifiser at du ikke er en robot', error);
          document.getElementById("first_name").value = " ";
          document.getElementById("last_name").value = " ";
          document.getElementById("company").value = " ";
          document.getElementById("email").value = " ";
          document.getElementById("phone_number").value = " ";
          document.getElementById("message").value = " ";

        })
    }

  },
  components: {VueRecaptcha}
}
</script>


<style scoped>

</style>
