<template>
  <div id="app">

    <Navbar />
    <router-view/>
    <Footer />
    <CookieBanner/>

  </div>
</template>


<script>

import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CookieBanner from '@/components/CookieBanner.vue'

export default {
  components:{
    Navbar,
    Footer,
    CookieBanner
  }

}

</script>

<style>
@import url(https://rsms.me/inter/inter.css);

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
