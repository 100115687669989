<template>
  <div class="solutions">
    <Header
      title="Tjenester"
      description="Dette er løsningene hos NetsGo. Vi tilbyr nettsider, digital markedsføring og SEO for å gjøre din bedrift synlig på nett."
    />
    <SolutionsWeb />
    <SolutionsMarketing />
    <SolutionsSEO />
    <CTA
      titleL1="Er noen av disse løsningene noe for din bedrift?"
      titleL2="Kontakt oss når som helst!"
      text=""
      buttonText="KONTAKT OSS"
      buttonUrl="/kontakt"
      img="./static/cta/road_sign.svg"
      imgAlt="Contact us illustration"
      imgClass="-mt-20 aspect-w-3 aspect-h-3 md:aspect-w-2 md:aspect-h-1"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import SolutionsWeb from '@/components/SolutionsWeb.vue'
import SolutionsMarketing from '@/components/SolutionsMarketing.vue'
import SolutionsSEO from '@/components/SolutionsSEO.vue'
import CTA from '@/components/CTA.vue'

export default {
  name: 'Solutions',
  components: {
    Header,
    SolutionsWeb,
    SolutionsMarketing,
    SolutionsSEO,
    CTA
  }
}
</script>
