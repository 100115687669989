<template>
  <div class="home">
    <Hero />
    <Divider />
    <FeaturesSection />
    <FeaturesCentered />
    <CTA
      titleL1="Lurer du på noe?"
      titleL2="Kontakt oss når som helst!"
      text=""
      buttonText="KONTAKT OSS"
      buttonUrl="/kontakt"
      img="./static/cta/team_spirit.svg"
      imgAlt="Contact us illustration"
      imgClass="-mt-20 aspect-w-3 aspect-h-3 md:aspect-w-2 md:aspect-h-1"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import FeaturesSection from '@/components/FeaturesSection.vue'
import Divider from '@/components/Divider.vue'
import CTA from '@/components/CTA.vue'
import FeaturesCentered from '@/components/FeaturesCentered.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    FeaturesSection,
    Divider,
    CTA,
    FeaturesCentered
  }
}
</script>
