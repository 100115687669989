<template>
  <div class="header">

        <div class="bg-gray-800">
      <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
        <div class="max-w-xl">
          <h2 class="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">{{ title }}</h2>
          <p class="mt-5 text-xl text-gray-400">{{ description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['title', 'description']
}

</script>

<style scoped>

</style>
