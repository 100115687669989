<template>

  <cookie-law theme="netsgo" buttonText="Godta og lukk">
    <div slot="message">
      NetsGo.no benytter informasjonskapsler (cookies). <router-link to="/personvern">Les mer</router-link>
    </div>
  </cookie-law>

</template>

<script>
import CookieLaw from 'vue-cookie-law'

  export default{

    data() {
      return {
        isHidden: true
      };
    },
    components: { CookieLaw }
  }
</script>

<style>

.Cookie--netsgo {
    background: #1f2937;
    color: #fff;
    padding: 0.5em;
    padding-left: 3em;
    padding-right: 3em;
}

.Cookie--netsgo .Cookie__button {
    background: #fb923c;
    padding: .425em 2.125em;
    color: #111830;
    border-radius: 0;
    font-weight: bold;
}

.cookie-link {
  text-decoration: underline;
}

</style>
