import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Solutions from '../views/Solutions.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/om-oss',
    name: 'About',
    component: About
  },
  {
    path: '/tjenester',
    name: 'Solutions',
    component: Solutions
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/personvern',
    name: 'Privacy',
    component: Privacy
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
