<template>
  <div class="privacy">
    <Header
      title="Personvern og cookies"
      description="Her finner du en oversikt over hvilke informasjonskapsler NetsGo benytter og formålet de benyttes til"
    />
    <Privacy />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Privacy from '@/components/Privacy.vue'

export default {
  name: 'Personvern',
  components: {
    Header,
    Privacy
  }
}
</script>
