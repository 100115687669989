<template>

  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="bg-gray-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div class="lg:self-center">
            <h2 class="text-3xl font-extrabold text-white sm:text-3xl">
              <span class="block text-yellow-400">{{ titleL1 }}</span>
              <span class="block">{{ titleL2 }}</span>
            </h2>
            <p class="mt-4 text-lg leading-6 text-gray-400">{{ text }}</p>

            <router-link :to="buttonUrl">
              <a href="#" class="mt-8 bg-yellow-400 border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-bold text-gray-900 hover:bg-yellow-500">{{ buttonText }}</a>
            </router-link>

          </div>
        </div>
        <div :class="imgClass">
          <img class="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" img draggable="false" :src="img" :alt="imgAlt">
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: ['titleL1', 'titleL2', 'text', 'buttonText', 'buttonUrl', "img", "imgAlt", "imgClass"]
}

</script>


<style scoped>

</style>
